<template>
  <div
    v-editable="blok"
    class="BrandListBlock layout-container mb-80"
    :class="desktopWidth('full')"
  >
    <div class="type-headline-lg mb-8 desk:type-headline-2xl desk:mb-12">{{ blok.title }}</div>
    <div
      v-if="(isMobile) || (listItemCount > 6)"
      class="relative"
    >
      <client-only>
        <swiper
          class="swiper-cover desk:!overflow-hidden !overflow-visible w-full desk:!pb-40 !pb-32"
          :modules="[Navigation, Pagination, A11y]"
          :loop="false"
          :space-between="8"
          :slides-per-view="2"
          :no-swiping="true"
          :breakpoints="{
            '768': {
              slidesPerView: 4,
              spaceBetween: 8,
            },
            '1024': {
              slidesPerView: 6,
              spaceBetween: 12,
            }
          }"
          :pagination="{
            clickable: true,
            dynamicBullets: true,
          }"
          :navigation="{
            nextEl: `.swiper-button-next.swiper-for-blok-${blok._uid}`,
            prevEl: `.swiper-button-prev.swiper-for-blok-${blok._uid}`,
          }"
        >
          <swiper-slide
            v-for="item in blok.listItem"
            :key="item._uid"
            class="deskLarge:!overflow-hidden"
            :class="{
              'aspect-portrait' : blok.aspectRatio === 'aspect-portrait',
              'aspect-square' : blok.aspectRatio === 'aspect-square',
            }"
          >
            <nuxt-link
              :to="$sbLinkFix(item.link)"
              :target="$sbLinkTarget(item.link)"
            >
              <nuxt-img
                v-if="item.image && item.image.filename"
                preset="standard"
                loading="lazy"
                sizes="mob375:100vw mob390:100vw mob414:100vw desk:248px"
                class="w-full h-full object-cover aspect-portrait"
                :src="item.image.filename"
                :alt="item.image.alt"
              />
            </nuxt-link>
          </swiper-slide>
        </swiper>
      </client-only>
      <div class="hidden desk:block">
        <div
          class="swiper-button-prev"
          :class="{[`swiper-for-blok-${blok._uid}`]: true}"
        />
        <div
          class="swiper-button-next"
          :class="{[`swiper-for-blok-${blok._uid}`]: true}"
        />
      </div>
    </div>
    <div v-else class="flex flex-row gap-8 desk:gap-12 w-full">
      <div
        v-for="item in blok.listItem"
        :key="item._uid"
        class="w-full"
        :class="{
          'aspect-portrait' : blok.aspectRatio === 'aspect-portrait',
          'aspect-square' : blok.aspectRatio === 'aspect-square',
        }"
      >
        <nuxt-link
          :to="$sbLinkFix(item.link)"
          :target="$sbLinkTarget(item.link)"
        >
          <nuxt-img
            v-if="item.image && item.image.filename"
            preset="standard"
            loading="lazy"
            sizes="mob375:100vw mob390:100vw mob414:100vw desk:248px"
            class="w-full h-full object-cover"
            :src="item.image.filename"
            :alt="item.image.alt"
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { StoryBlokAsset, StoryBlokLink, StoryBlokPropBase } from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';

import { Pagination, A11y, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';

const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

type Item = StoryBlokPropBase & {
  image: StoryBlokAsset;
  link: StoryBlokLink;
}

type Props = StoryBlokPropBase & {
  title: string;
  listItem: Item[];
  aspectRatio: 'aspect-portrait' | 'aspect-square',
}

const props = defineProps<{
  blok: Props,
}>();

const listItemCount = computed(() => props.blok.listItem.length);

const { desktopWidth } = useDesktopWidth(props);
</script>
<style lang="postcss">
.swiper-button-next {
  @apply !-right-16;
}
.swiper-button-prev {
  @apply !-left-16;
}
</style>
